.App {
  height: 100vh;
  background-color: rgb(20, 20, 20);
  overflow: scroll;
  text-align: center;
  overflow: hidden;
}

.background-video {
  width: 100vw;
  height: 100vh;
  object-fit: cover;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  

}

.container {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 30px 0 30px;
  color: white;
  position: relative;
}

.authed-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 30px;
}

.header-container {

}

.header {
  /* margin: 0; */
  font-size: 150px;
  font-weight: bold;
  font-family: "Matroska";
  color: rgba(0, 0, 0, 0.0);
  -webkit-text-stroke-width: 2px;
  -webkit-text-stroke-color: white;
  line-height: 5%;
  margin-bottom: 50px;

}
.header:hover {
  color: rgba(255, 255, 255, 0.781);
  -webkit-text-stroke-color: rgba(255, 255, 255, 0.678);
  transition-duration: .2s;
}

.sub-text {
  font-size: 25px;
  animation: animate 40s linear infinite;
  white-space: nowrap;
}
@keyframes animate {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}

.gradient-text {
  background: -webkit-linear-gradient(left, #60c657 30%, #35aee2 60%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.cta-button {
  height: 45px;
  border: 0;
  width: auto;
  padding-left: 40px;
  padding-right: 40px;
  border-radius: 10px;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
}

.connect-wallet-button {
  color: white;
  background: -webkit-linear-gradient(left, #ff8867, #ff52ff);
  background-size: 200% 200%;
  animation: gradient-animation 4s ease infinite;
}

.mint-button {
  background: -webkit-linear-gradient(left, #4e44ce, #35aee2);
  background-size: 200% 200%;
  animation: gradient-animation 4s ease infinite;
  margin-left: 10px;

}

.footer-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 100%;
  bottom: 0;
  left: 0;
  /* padding-bottom: 45px; */
}

.twitter-logo {
  width: 35px;
  height: 35px;
}

.footer-text {
  font-size: 16px;
  font-weight: bold;
  color: white;
}

.connected-container input[type='text'] {
  display: inline-block;
  padding: 10px;
  width: 50%;
  height: 60px;
  font-size: 16px;
  box-sizing: border-box;
  background-color: rgba(0, 0, 0, 0.25);
  border: none;
  border-radius: 10px;
  margin: 50px auto;
}

.connected-container button {
  height: 50px;
}

/* KeyFrames */
@-webkit-keyframes gradient-animation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
@-moz-keyframes gradient-animation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
@keyframes gradient-animation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

@media screen and (max-width: 1000px ) {
  .header {
    font-size: 10vw;
    /* transition-duration: .5s; */
  }
}